import { get, post } from "@/utils/https";

// 个人申请书
export function personApplyAuthUrl(prePlatOrderNo) {
  return get(`/api/v1/common/cus/apply/wjf/getPersonSign/${prePlatOrderNo}`);
}
// 确认函授
export function checkedAuthCode(data) {}
// 发送授权验证码
export function authCode(data) {}
